/**
 * sanitize.css is a CSS library that provides consistent,
 * cross-browser default styling of HTML elements alongside useful defaults.
 * Release: 13.0.0
 *
 * Note: This file was changed to set color and line-height,
 * and to include 'VodafoneRg' as default font family.
 * Also system-ui and ui-monospace have been removed.
 */

/**
 * Use the default user interface font in all browsers (opinionated).
 */

html {
  color: #262626; /* colors.$262626 */
  font-family: /* fonts.regular */ VodafoneRg, /* macOS 10.11-10.12 */ -apple-system,
    /* Windows 6+ */ 'Segoe UI', /* Android 4+ */ Roboto, /* Ubuntu 10.10+ */ Ubuntu,
    /* Gnome 3+ */ Cantarell, /* KDE Plasma 5+ */ 'Noto Sans', /* fallback */ sans-serif,
    /* macOS emoji */ 'Apple Color Emoji', /* Windows emoji */ 'Segoe UI Emoji',
    /* Windows emoji */ 'Segoe UI Symbol', /* Linux emoji */ 'Noto Color Emoji';
  line-height: 1.15;
}

/**
 * Use the default monospace user interface font in all browsers (opinionated).
 */

code,
kbd,
samp,
pre {
  font-family: /* macOS 10.10+ */ Menlo, /* Windows 6+ */ Consolas, /* Android 4+ */ 'Roboto Mono',
    /* Ubuntu 10.10+ */ 'Ubuntu Monospace', /* KDE Plasma 5+ */ 'Noto Mono',
    /* KDE Plasma 4+ */ 'Oxygen Mono', /* Linux/OpenOffice fallback */ 'Liberation Mono',
    /* fallback */ monospace, /* macOS emoji */ 'Apple Color Emoji',
    /* Windows emoji */ 'Segoe UI Emoji', /* Windows emoji */ 'Segoe UI Symbol',
    /* Linux emoji */ 'Noto Color Emoji';
}

