/**
 * Use this file to add custom styles to the globals.css
 */

html,
body,
#__next {
  height: 100%;
}

:focus-visible {
  outline: 1px solid #0d0d0d;
  outline-offset: 1px;
}

