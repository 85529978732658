/**
 * All in one @font-face.
 */

@font-face {
  font-family: 'Vodafone';
  font-weight: 300;
  font-display: block;
  src: url('../fonts/VodafoneLt-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Vodafone';
  font-weight: 400;
  font-display: block;
  src: url('../fonts/VodafoneRg-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Vodafone';
  font-weight: 700;
  font-display: block;
  src: url('../fonts/VodafoneRg-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Vodafone';
  font-weight: 800;
  font-display: block;
  src: url('../fonts/VodafoneExB-Regular.woff2') format('woff2');
}

/**
 * Legacy, only for retrocompatibility.
 */

@font-face {
  font-family: 'VodafoneIsRg';
  font-weight: normal;
  font-display: block;
  src: url('../fonts/Instrument-sans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'VodafoneIsRgB';
  font-weight: normal;
  font-display: block;
  src: url('../fonts/Instrument-sans-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'VodafoneLt';
  font-weight: normal;
  font-display: block;
  src: url('../fonts/VodafoneLt-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'VodafoneRg';
  font-weight: normal;
  font-display: block;
  src: url('../fonts/VodafoneRg-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'VodafoneRg';
  font-weight: bold;
  font-display: block;
  src: url('../fonts/VodafoneRg-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'VodafoneExB';
  font-weight: normal;
  font-display: block;
  src: url('../fonts/VodafoneExB-Regular.woff2') format('woff2');
}

/**
 * Fallback for external html/css.
 */

@font-face {
  font-family: 'Vodafone Lt';
  font-weight: normal;
  font-display: block;
  src: url('../fonts/VodafoneLt-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Vodafone Rg';
  font-weight: normal;
  font-display: block;
  src: url('../fonts/VodafoneRg-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Vodafone Rg';
  font-weight: bold;
  font-display: block;
  src: url('../fonts/VodafoneRg-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Vodafone ExB';
  font-weight: normal;
  font-display: block;
  src: url('../fonts/VodafoneExB-Regular.woff2') format('woff2');
}

