/**
 * Vodafone Typography classes: media query for mobile and above.
 */

.h1-extrabold,
.h2-extrabold,
.h3-extrabold,
.h4-extrabold,
.h5-extrabold,
.h6-extrabold,
.body1-extrabold,
.body2-extrabold,
.body3-extrabold {
  font-weight: 800;
}

.h1-bold,
.h2-bold,
.h3-bold,
.h4-bold,
.h5-bold,
.h6-bold,
.body1-bold,
.body2-bold,
.body3-bold,
.body1-link,
.body2-link,
.body3-link,
.button2-bold,
.tag-bold {
  font-weight: 700;
}

.h1-regular,
.h2-regular,
.h3-regular,
.h4-regular,
.h5-regular,
.h6-regular,
.body1-regular,
.body2-regular,
.body3-regular,
.button1-regular,
.footnote1-regular,
.footnote2-regular,
.tag-regular {
  font-weight: 400;
}

.h1-light,
.h2-light,
.h3-light,
.h4-light,
.h5-light,
.h6-light {
  font-weight: 300;
}

.h1-extrabold {
  font-size: 2.625rem; /* 42px */
  line-height: 1.15; /* 42/48 */
}

.h2-extrabold,
.h2-bold,
.h2-regular,
.h2-light {
  font-size: 2.25rem; /* 36px */
  line-height: 1.2; /* 36/44 */
}

.h3-extrabold,
.h3-bold,
.h3-regular,
.h3-light {
  font-size: 2rem; /* 32px */
  line-height: 1.25; /* 32/40 */
}

.h4-extrabold,
.h4-bold,
.h4-regular,
.h4-light {
  font-size: 1.75rem; /* 28px */
  line-height: 1.3; /* 28/36 */
}

.h5-extrabold,
.h5-bold,
.h5-regular,
.h5-light {
  font-size: 1.5rem; /* 24px */
  line-height: 1.35; /* 24/32 */
}

.h6-extrabold,
.h6-bold,
.h6-regular,
.h6-light {
  font-size: 1.25rem; /* 20px */
  line-height: 1.4; /* 20/28 */
}

.body1-extrabold,
.body1-bold,
.body1-link,
.body1-regular {
  font-size: 1.125rem; /* 18px */
  line-height: 1.35; /* 18/24 */
}

.body2-extrabold,
.body2-bold,
.body2-link,
.body2-regular {
  font-size: 1rem; /* 16px */
  line-height: 1.4; /* 16/22 */
}

.body3-extrabold,
.body3-bold,
.body3-link,
.body3-regular,
.footnote1-regular {
  font-size: 0.875rem; /* 14px */
  line-height: 1.3; /* 14/18 */
}

.body1-link,
.body2-link,
.body3-link {
  text-decoration: underline;
}

.body1-link:hover,
.body2-link:hover,
.body3-link:hover {
  color: #e60000;
}

.button1-regular {
  font-size: 1rem; /* 16px */
  line-height: 1; /* differs from DS typography */
}

.button2-bold {
  font-size: 0.875rem; /* 14px */
  line-height: 1; /* differs from DS typography */
}

.button2-bold,
.tag-bold {
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.footnote2-regular {
  font-size: 0.75rem; /* 12px */
  line-height: 1.35; /* 12/16 */
}

.tag-bold {
  font-size: 0.75rem; /* 12px */
  line-height: 1; /* differs from DS typography */
}

/**
  * Typography classes: media query for tablet and above.
  */

@media (min-width: 47.93rem) {
  .h1-extrabold {
    font-size: 4rem; /* 64px */
    line-height: 1.15; /* 64/72 */
  }

  .button1-regular {
    font-size: 1.125rem; /* 18px */
  }
}

/**
  * Typography classes: media query for desktop and above.
  */

@media (min-width: 64rem) {
  .h1-extrabold {
    font-size: 4.375rem; /* 70px */
    line-height: 1.1; /* 70/76 */
  }

  .h2-extrabold,
  .h2-bold,
  .h2-regular,
  .h2-light {
    font-size: 3.25rem; /* 52px */
    line-height: 1.3; /* 52/68 */
  }

  .h3-extrabold,
  .h3-bold,
  .h3-regular,
  .h3-light {
    font-size: 2.75rem; /* 44px */
    line-height: 1.2; /* 44/52 */
  }

  .h4-extrabold,
  .h4-bold,
  .h4-regular,
  .h4-light {
    font-size: 2.25rem; /* 36px */
    line-height: 1.35; /* 36/48 */
  }

  .h5-extrabold,
  .h5-bold,
  .h5-regular,
  .h5-light {
    font-size: 1.75rem; /* 28px */
    line-height: 1.3; /* 28/36 */
  }

  .h6-extrabold,
  .h6-bold,
  .h6-regular,
  .h6-light {
    font-size: 1.375rem; /* 22px */
    line-height: 1.45; /* 22/32 */
  }
}

