/**
 * sanitize.css is a CSS library that provides consistent,
 * cross-browser default styling of HTML elements alongside useful defaults.
 * Release: 13.0.0
 */

/**
 * Restrict sizing to the page width in all browsers (opinionated).
 */

:where(iframe, img, input, video, select, textarea) {
  height: auto;
  max-width: 100%;
}

